import { isSpecialSubdomain } from "~/utils/helper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();
  const { $axios } = useNuxtApp();
  const toast = useMessage();

  const host = window.location.host;
  const subdomain = host.split(".")[0];

  if (isSpecialSubdomain(subdomain)) {
    return navigateTo("/login");
  }

  if (!to.query.token || !to.query.email) {
    return navigateTo(`/login`);
  }

  try {
    if (to.query.token && to.query.email) {
      await $axios.get("/sanctum/csrf-cookie");
      const data = await $axios
        .post(`/users/valid_invite`, {
          token: to.query.token,
          email: to.query.email,
        })
        .then((res) => res.data);

      if (data.new_user) {
        if (auth.user) {
          auth.logout();
        }
        return;
      } else {
        const data = await $axios.post(`/users/valid_invite`, {
          token: to.query.token,
          email: to.query.email,
          accept: true,
        });
        if (data && auth.user) {
          return navigateTo("/switch");
        } else {
          toast.message({
            message:
              "Thank you accepting the invitation. Please login to access your account",
          });
          return navigateTo("/login");
        }
      }
    }
  } catch {
    toast.message({
      message: "Token is invalid",
    });
    return navigateTo("/login");
  }

  return;
});
